import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import Quote from "./quote"

const QuoteEAE = ({className}: {className?:string}) => {
  const data = useStaticQuery(graphql`
        query {
            quoteImage: file(relativePath: { eq: "profile-erik.jpg" }) {
                base
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 700)
                }
            }
        }
    `)

  return (
    <Quote
      className={className} 
      text="It's pretty obvious you've thought of everything before. The learning curve is quite small and it's very well documented. We managed to get SAP integrations done in under 4 days with a senior ABAP consultant." 
      author="Fatih Aslan"
      workTitle="SAP System Manager at EAE Elektrik"
    />
  )
}

export default QuoteEAE