import React from "react";
import Typewriter from 'typewriter-effect/dist/core';
import CheckboxList from "./checkbox_list";
import BoxLoading from "./tiny/boxload_name";
import apiResponseImage from "../images/api-response-box.png"

class ApiShowcase extends React.Component {
  public state = {
    typewriterStarted: false,
    typewriterCompleted: false
  }

  private inputObject = {
    box_types: ["Box M (51x41x41)", "Box L (59x40x37)", "Box XL (60x48x42)"],
    items: [
      {
        label: "A",
        length: 39,
        width: 40,
        height: 30,
        weight: 1.7,
        quantity: 1,
      },
      {
        label: "B",
        length: 20,
        width: 35,
        height: 35,
        weight: 1.2,
        quantity: 1,
      },
    ],
  };

  private outputObject = {
    response: {
      boxes: [
        {
          name: "Box L (59x40x37)",
          weight: 2.9,
          items: [
            "A", "B"
          ],
          items_in_box: 2
        },
      ],
    },
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount(): void {
    const observer = new IntersectionObserver((entries) => {
      if(!this.state.typewriterStarted && entries[0].intersectionRatio > 0.25) {
       this.setupWriter()
      }
    }, { threshold: 0.25 });    
    observer.observe(document.querySelector("#typewriter-container")!);
  }

  private setupWriter() {
    this.setState({...this.state, typewriterStarted: true})
    const typewriter = new Typewriter('#typewriter', {
      autoStart: false,
      loop: false,
      delay: 20
    })
    typewriter.typeString(this.getInputString()).callFunction(() => {
      typewriter.stop();
      const cursor =
        document.getElementsByClassName(
          "Typewriter__cursor"
        );
      cursor.item(0)?.remove();
      this.setState({...this.state, typewriterCompleted: true});
    })
    typewriter.start()
  }

  private isObject(obj: any, key: string) {
    return typeof obj[key] === "object";
  }

  private isArray(obj: any, key: string) {
    return Array.isArray(obj[key]);
  }

  private getTextForPropertyType(val: any) {
    let spanClass = typeof val;
    let value = val;
    if (typeof val === "string") {
      if (value === "[" || value === "{") {
        spanClass = "undefined";
      } else {
        value = `"${value}"`;
      }
    }
    return `<span class="${spanClass}">${value}</span>`;
  }

  private createRow(value: any, key: any, obj: any, depth: number) {
    const objectKeys = Object.keys(obj);
    const isLast = objectKeys[objectKeys.length - 1] === key;
    const endCharacter = isLast ? "" : ",";
    return {
      text: isNaN(key)
        ? `${key}: ${this.getTextForPropertyType(value)}${endCharacter}`
        : this.getTextForPropertyType(value) + endCharacter,
      depth,
    };
  }

  private createObjectOrArrayStartRow(
    character: string,
    key: any,
    depth: number
  ) {
    return {
      text: isNaN(key) ? `${key}: ${character} ` : character,
      depth,
    };
  }

  private createObjectOrArrayEndRow(
    character: string,
    key: any,
    obj: any,
    depth: number
  ) {
    const objectKeys = Object.keys(obj);
    const isLast = objectKeys[objectKeys.length - 1] === key;
    const endCharacter = isLast ? "" : ",";
    return {
      text: character + endCharacter,
      depth,
    };
  }

  private getRowsFromObject(object: any) {
    let depth = 1;
    const rows: any[] = [];

    const iterate = (obj: any) => {
      Object.keys(obj).forEach((key) => {
        if (this.isArray(obj, key)) {
          rows.push(this.createObjectOrArrayStartRow("[", key, depth));
          depth++;
          iterate(obj[key]);
          depth--;
          rows.push(this.createObjectOrArrayEndRow("]", key, obj, depth));
        } else if (this.isObject(obj, key)) {
          rows.push(this.createObjectOrArrayStartRow("{", key, depth));
          depth++;
          iterate(obj[key]);
          depth--;
          rows.push(this.createObjectOrArrayEndRow("}", key, obj, depth));
        } else {
          rows.push(this.createRow(obj[key], key, obj, depth));
        }
      });
    };
    iterate(object);
    return rows;
  }

  private getInputString() {
    const inputStartRow = {
      text: "<span style='color:#C0AD60'>const</span> input = {",
      depth: 0,
    };
    const inputEndRow = { text: "}", depth: 0 };
    const rowsFromObject: { text: string; depth: number }[] =
      this.getRowsFromObject(this.inputObject);
    return [inputStartRow, ...rowsFromObject, inputEndRow]
      .map(
        (row) =>
          `<span style="margin-left:${row.depth * 12}px">${row.text}</span>`
      )
      .join("<br>");
  }

  private getSpanStyle(depth: number) {
    return {
      display: "block",
      marginLeft: `${depth * 8}px`,
    };
  }

  render() {
    const output = () => {
      return this.getRowsFromObject(this.outputObject).map((item, index) => (
        <span
          dangerouslySetInnerHTML={{ __html: item.text }}
          style={this.getSpanStyle(item.depth)}
          key={index}
        ></span>
      ));
    };


    return (
      <section>
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          <div className="items-center gap-8 lg:grid lg:grid-cols-12 xl:gap-16">
            <div className="lg:col-span-6 sm:text-lg">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900">
                Use our simple API
              </h2>
              <p className="mb-8 font-light lg:text-xl text-gray-500">
                We're a bunch of developers at <BoxLoading />, and we know the
                frustration of incomplete and confusing integrations. We're not
                that. We're proud to deliver something simple, yet useful.
              </p>
              <CheckboxList
                items={[
                  <span>
                    Simple API for quick integrations
                    <p className="font-light py-1 text-gray-500">
                      Get <b>BoxLoading</b> plugged in and running within a few hours.
                      Not days or weeks.
                    </p>
                  </span>,
                  <span>
                    Clear Documentation
                    <p className="font-light py-1 text-gray-500">
                      Plentyful of examples for almost every scenario.
                    </p>
                  </span>,
                  <span>
                    Fast Response Times
                    <p className="font-light py-1 text-gray-500">
                      Our average response time is a fairly impressive{" "}
                      <b>300ms</b> for an NP-complete problem, but our median
                      time is actually a lot quicker.
                    </p>
                  </span>,
                  <span>
                  Built on Cargo-Planner engine
                  <p className="font-light py-1 text-gray-500">
                    <b>BoxLoading</b> utilizes the <a href="https://cargo-planner.com" target="_blank" className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800">Cargo-Planner</a> engine, which has been trusted by the largest forwarders, manufacturers and airlines since 2015.
                  </p>
                </span>,
                ]}
              />
            </div>

            <div className="lg:col-span-6 w-full flex flex-col relative">
              <div className="outer bg-purple-800">
                <div className="dot red"></div>
                <div className="dot amber"></div>
                <div className="dot green"></div>
                <span className="absolute left-[50%] translate-x-[-50%] text-white">
                  API Example
                </span>
              </div>

              <div id="typewriter-container" className="min-h-[1200px] md:min-h-[610px] relative flex flex-col md:flex-row justify-between">
                <div className="flex-1 relative">
                  <div id="typewriter"></div>
                </div>
                <div className="border-l border-gray-100 opacity-20"></div>
                <div className="flex-1">
                  <div className={`opacity-0 transition-opacity ease-in ${this.state.typewriterCompleted === true && "opacity-100"}`} >
                    {output()}
                    <img className="mx-auto mt-8 max-w-[200px]" src={apiResponseImage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ApiShowcase;
