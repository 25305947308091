import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import CheckboxList from "./checkbox_list";
import Section from "./section";
import BoxLoading from "./tiny/boxload_name";

const Integration = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      integrationImage: file(relativePath: { eq: "integration-people-purple.png" }) {
        base
        childImageSharp {
          gatsbyImageData(quality: 100, width: 700)
        }
      }
    }
  `);

  return (
    <Section
      header="Seamless integration"
      image={
        <GatsbyImage
          className="max-w-[200px] md:max-w-[380px]"
          alt={data.integrationImage.base.split(".")[0]}
          image={data.integrationImage.childImageSharp.gatsbyImageData}
        />
      }
      {...props}
    >
      <p className="mb-8 font-light lg:text-xl text-gray-500">
        From the moment a customer reaches checkout, you will already know what
        type of packaging that will fit their order. No manual steps need to be
        taken.
      </p>
      <CheckboxList
        items={[
          <span>
            Accurate forecasting
            <p className="font-light py-1 text-gray-500">
              Forecast shipping costs with a higher accuracy, and reduce
              guesswork.
            </p>
          </span>,
          <span>
            Stateless
            <p className="font-light py-1 text-gray-500">
              Nothing is saved in our database. Your transactions with your
              customers are not our business. Just how they are packaged.
            </p>
          </span>,
          <span>
            Blazingly fast
            <p className="font-light py-1 text-gray-500">
              Calculating the perfect packaging is a difficult problem.{" "}
              <BoxLoading /> is so fast you won't even know we're there.
            </p>
          </span>,
        ]}
      />

      {/* <p className="font-light lg:text-xl text-gray-500">
        Deliver great service experiences fast - without the complexity of
        traditional ITSM solutions.
      </p> */}
    </Section>
  );
};

export default Integration;
