import React from "react";

const BoxLoading = ({}) => {
  return (
    <span>
      <b>BoxLoading</b>
    </span>
  );
};

export default BoxLoading;
