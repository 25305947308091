import * as React from "react";
import type { HeadFC } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/hero";
import ApiShowcase from "../components/api_showcase";
import Integration from "../components/integration";
import SEO from "../components/seo";
import ScheduleDemo from "../components/schedule_demo";
import Benefits from "../components/benefits";
import ECommerce from "../components/e_commerce";
import QuoteEAE from "../components/quotes/quote_eae";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="BoxLoading - waste no space with optimized box selection" />
      <Hero />
      <ECommerce className="bg-gray-100" />
      <Benefits reverse />
      <Integration className="bg-gray-100" />
      <ApiShowcase />
      <QuoteEAE className="bg-gray-100" />
      {/* <Pricing /> */}
      <ScheduleDemo className="py-16" />
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>BoxLoading</title>;
