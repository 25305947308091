import React, { ReactElement } from "react";

const Section = ({
  className,
  children,
  image,
  subheader,
  header,
  reverse,
}: {
  className?: string;
  children?: any;
  image?: ReactElement;
  subheader?: string;
  header?: string;
  reverse?: boolean;
}) => {
  const flexDirection = reverse ? "lg:flex-row-reverse" : "lg:flex-row";
  return (
    <section className={"bg-white dark:bg-primary-dark  " + className}>
      <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6 dark:text-white">
        <div
          className={
            "flex-col flex justify-around  items-center " + flexDirection
          }
        >
          {image && (
            <div className="flex lg:basis-[40%]">{image}</div>
          )}
          <div
            className={"flex justify-center " + (image ? "lg:basis-[40%]" : "")}
          >
            <div>
              {subheader && (
                <p className="text-lg font-medium text-purple-600 dark:text-purple-500">
                  {subheader}
                </p>
              )}
              {header && (
                <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white md:text-3xl">
                  {header}
                </h2>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;
