import React from "react"

const Stats = () => {
    return(
        <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
            <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 sm:grid-cols-3">
                <div className="flex flex-col p-4 text-gray-900">
                    <div className="md:flex">
                    {/* <svg className="w-10 h-10 my-auto text-purple-700" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke-width="2" d="M6.5,10.5 L12,13.5 L17.5,10.5 L17.5,4.5 L12,1.5 L6.5,4.5 L6.5,10.5 Z M6.5,4.5 L12,7.5 L17.5,4.5 M12,7.5 L12,13.5 L12,7.5 Z M1,19.5 L6.5,22.5 L12,19.5 L12,13.5 L6.5,10.5 L1,13.5 L1,19.5 Z M1,13.5 L6.5,16.5 L12,13.5 M6.5,16.5 L6.5,22.5 L6.5,16.5 Z M12,19.5 L17.5,22.5 L23,19.5 L23,13.5 L17.5,10.5 L12,13.5 L12,19.5 Z M12,13.5 L17.5,16.5 L23,13.5 M17.5,16.5 L17.5,22.5 L17.5,16.5 Z"/>
                    </svg> */}

                    <svg className="w-14 h-14 my-auto text-purple-700" fill="currentColor" viewBox="0 0 490 490">
                        <g>
                            <path d="M477.613,48.032l-42.378,30.895v303.914H0v21.035h35.916c-2.671,5.231-4.178,11.15-4.178,17.415
                                c0,21.199,17.255,38.45,38.455,38.45c21.199,0,38.444-17.251,38.444-38.45c0-6.265-1.507-12.184-4.176-17.415h236.834
                                c-2.671,5.231-4.178,11.15-4.178,17.415c0,21.199,17.255,38.45,38.454,38.45c21.2,0,38.455-17.251,38.455-38.45
                                c0-6.265-1.508-12.184-4.178-17.415h46.423V89.619L490,65.031L477.613,48.032z M87.602,421.292c0,9.603-7.805,17.415-17.409,17.415
                                c-9.604,0-17.42-7.811-17.42-17.415c0-9.604,7.817-17.415,17.42-17.415C79.797,403.877,87.602,411.688,87.602,421.292z
                                M392.99,421.292c0,9.603-7.817,17.415-17.42,17.415c-9.604,0-17.419-7.811-17.419-17.415c0-9.604,7.815-17.415,17.419-17.415
                                C385.174,403.877,392.99,411.688,392.99,421.292z"/>
                            <path d="M386.088,175.409H222.389v161.465h163.699V175.409z M365.053,315.839H243.424V196.444h121.629V315.839z"/>
                            <path d="M366.05,30.258H242.427v118.928H366.05V30.258z M345.015,128.151h-81.553V51.293h81.553V128.151z"/>
                            <path d="M195.438,175.409h-163.7v161.465h163.7V175.409z M174.403,315.839H52.773V196.444h121.63V315.839z"/>
                        </g>
                    </svg>

                        <div className="m-y-auto md:ml-6">
                            <h3 className="text-xl font-semibold">200.000 packages</h3>
                            <p className="font-light text-gray-500 sm:text-lg">planned daily</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col p-4 text-gray-900">
                    <div className="md:flex">
                        <svg className="w-14 h-14 my-auto text-purple-700" fill="currentColor" viewBox="0 0 490 490">
                            <g>
                            <path d="M427.978,106.769C427.978,47.892,380.086,0,321.209,0C262.331,0,214.44,47.892,214.44,106.769
                                c0,55.434,42.455,101.123,96.561,106.278v256.536H62.022V490h304.745v-20.417h-35.35V213.047
                                C385.522,207.892,427.978,162.203,427.978,106.769z M321.209,193.121c-47.613,0-86.352-38.739-86.352-86.352
                                c0-47.612,38.74-86.352,86.352-86.352c47.612,0,86.352,38.741,86.352,86.352C407.561,154.382,368.82,193.121,321.209,193.121z"/>
                            <rect x="311" y="27.426" width="20.417" height="19.858"/>
                            <rect x="311" y="166.255" width="20.417" height="19.858"/>
                            <rect x="380.694" y="96.561" width="19.858" height="20.417"/>
                            <rect x="241.865" y="96.561" width="19.858" height="20.417"/>
                            <path d="M327.18,115.044l-44.053,31.78l-11.943-16.547l44.053-31.782L327.18,115.044z"/>
                            <path d="M99.387,448.818H259.67c5.642,0,10.208-4.566,10.208-10.208V299.541c0-1.257-0.383-4.476-2.471-6.651
                                c-0.008-0.008-0.014-0.019-0.022-0.028l-29.548-34.124c-1.935-2.243-4.755-3.529-7.717-3.529H128.935
                                c-2.96,0-5.782,1.286-7.715,3.529L91.67,292.862c-0.01,0.011-0.018,0.024-0.028,0.036c-0.51,0.594-2.464,2.92-2.464,6.643V438.61
                                C89.178,444.252,93.745,448.818,99.387,448.818z M133.601,275.625h91.854l11.873,13.708H121.728L133.601,275.625z M109.595,309.75
                                h139.867v118.652H109.595V309.75z"/>
                            </g>
                        </svg>
                        <div className="m-y-auto md:ml-6">
                            <h3 className="text-xl font-semibold">450.000 tonnes</h3>
                            <p className="font-light text-gray-500 sm:text-lg">optimized weekly</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col p-4 text-gray-900">
                    <div className="md:flex">
                        <svg className="w-14 h-14 my-auto text-purple-700" fill="currentColor" viewBox="0 0 490 490">
                            <g>
                                <path d="M276.161,58.174V20.417h30.495V0h-81.407v20.417h30.495V58.36c-22.555,1.258-44.569,5.978-65.597,14.11
                                    c-5.258,2.033-7.871,7.945-5.836,13.204c2.039,5.263,7.95,7.885,13.204,5.836c22.46-8.682,46.177-13.089,70.492-13.089
                                    c107.845,0,195.583,87.738,195.583,195.584c0,107.841-87.739,195.578-195.583,195.578c-100.347,0-183.273-75.972-194.315-173.416
                                    l57.175,47.177c4.347,3.588,10.782,2.971,14.37-1.381c3.589-4.347,2.971-10.781-1.38-14.37l-75.077-61.948
                                    c-2.632-2.517-10.856-4.775-15.487,3.046L7.24,354.446c-2.667,4.969-0.953,11.453,4.162,13.822
                                    c6.519,3.019,11.983-0.733,13.822-4.162l29.626-55.166C71.599,411.485,160.799,490,268.006,490c119.101,0,216-96.895,216-215.995
                                    C484.007,157.636,391.496,62.479,276.161,58.174z"/>
                                <path d="M268.006,227.2c-25.805,0-46.8,20.995-46.8,46.805c0,25.805,20.995,46.8,46.8,46.8c6.166,0,12.052-1.21,17.448-3.387
                                    l69.867,77.768c3.763,4.191,10.208,4.546,14.415,0.768c4.192-3.764,4.536-10.219,0.768-14.416l-68.119-75.821
                                    c7.704-8.345,12.421-19.486,12.421-31.711C314.806,248.195,293.811,227.2,268.006,227.2z M268.006,300.388
                                    c-14.55,0-26.383-11.833-26.383-26.383c0-14.55,11.833-26.388,26.383-26.388c14.55,0,26.383,11.838,26.383,26.388
                                    C294.39,288.555,282.556,300.388,268.006,300.388z"/>
                            </g>
                        </svg>
                        <div className="m-y-auto md:ml-6">
                            <h3 className="text-xl font-semibold">0,3 seconds</h3>
                            <p className="font-light text-gray-500 sm:text-lg">average calculation time</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    )
}

export default Stats

