import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import CheckboxList from "./checkbox_list";
import Section from "./section";

const Benefits = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      ecommerceBoxImage: file(relativePath: { eq: "get-packaging-right-single-purple.png" }) {
        base
        childImageSharp {
          gatsbyImageData(quality: 100, width: 800)
        }
      }
    }
  `);
  return (
    <Section
      header="Get your packaging right"
      subheader="Trusted Worldwide"
      image={
        <GatsbyImage
          className="max-w-[200px] md:max-w-[380px]"
          alt={data.ecommerceBoxImage.base.split(".")[0]}
          image={data.ecommerceBoxImage.childImageSharp.gatsbyImageData}
        />
      }
      {...props}
    >
      <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
        Gain control of your outbound packages. Our API-first software will find
        the most efficent solution to pack your products.
      </p>
      <CheckboxList
        items={[
          <span>
            Saves CO<sub>2</sub> and money by shipping less air
          </span>,
          //   <span>Integrates directly with your system</span>,
          <span>Optimizes your packaging in milliseconds</span>,
          //   <span>Simple API and clear documentation</span>,
          <span>
            Supports boxes, pallets, trailers, containers and a lot more
          </span>,
        ]}
      />
    </Section>
  );
};

export default Benefits;
