import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import CheckboxList from "./checkbox_list";
import Section from "./section";

const ECommerce = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      benefitsImage: file(relativePath: { eq: "boxes-man-purple.png" }) {
        base
        childImageSharp {
          gatsbyImageData(quality: 100, width: 800)
        }
      }
    }
  `);

  return (
    <Section
      image={
        <GatsbyImage
          className="max-w-[200px] md:max-w-[400px] mt-8"
          alt={data.benefitsImage.base.split(".")[0]}
          image={data.benefitsImage.childImageSharp.gatsbyImageData}
        />
      }
      subheader="Excellent for"
      header="E-Commerce"
      {...props}
    >
      <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
        Don't you hate it when you get something from an online store and the
        packaging is far too large for the product? We're here to help you
        ensure that never happens with shipments from you.
      </p>
      <CheckboxList
        items={[
          <span>Reduce package sizes and shipping costs</span>,
          <span>
            Save CO<sub>2</sub> on every order
          </span>,
          <span>Accurately calculate freight cost on checkout</span>,
          //   <span>Handles multiple product shapes and types</span>,
        ]}
      />
    </Section>
  );
};

export default ECommerce;
