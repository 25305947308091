import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import CalendlyButton from "./calendly"
import Stats from "./stats"

const Hero = () => {
    const data = useStaticQuery(graphql`
        query {
            heroImage: file(relativePath: { eq: "boxes-hero.png" }) {
                base
                childImageSharp {
                  gatsbyImageData(quality: 100, height: 700)
                }
            }
        }
    `)

    return(
        <section className="bg-white dark:bg-gray-900">
            <div className="flex flex-col">
                <div className="grid max-w-screen-xl px-4 pt-20 pb-4 mx-auto lg:gap-8 xl:gap-8 lg:py-16 lg:grid-cols-12">
                    <div className="mr-auto place-self-center lg:col-span-6">
                        <h1 className="max-w-2xl text-primary mb-4 text-4xl font-bold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">Waste no space</h1>
                        <h1 className="max-w-2xl mb-4 text-4xl font-bold leading-none tracking-tight md:text-4xl xl:text-4xl dark:text-white">with optimized box selection</h1>
                        <p className="max-w-2xl font-light md:text-lg lg:text-xl dark:text-gray-400">
                            Stop guessing shipping costs and what packaging material to use. Always select the right box to load in with <b>BoxLoading</b>.
                        </p>
                        <div className="hidden sm:flex sm:mt-8 space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                            <CalendlyButton />
                        </div>
                    </div>
                    <div className="max-w-[360px] md:max-w-[500px] mx-auto lg:max-w-none lg:mt-0 lg:col-span-6 lg:flex">
                        <GatsbyImage alt={data.heroImage.base.split(".")[0]} image={data.heroImage.childImageSharp.gatsbyImageData} />
                        <div className="sm:hidden space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                            <CalendlyButton />
                        </div>
                    </div>                
                </div>
                <Stats />
                {/* TODO: show companies without having to scroll */}
                {/* <Companies /> */}

            </div>
            
        </section>
    )
}

export default Hero